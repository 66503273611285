<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
        sm="12"
        order="5"
        order-md="3"
      >
        <payments-card></payments-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <monthly-payments></monthly-payments>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import useDashboardBalance from '@/views/dashboards/general/useDashboardBalance'

// icons

// demos
import PaymentsCard from './PaymentsCard.vue'
import MonthlyPayments from './MonthlyPayments.vue'
import store from '@/store'
import storeModule from '../storeModule'

export default {
  components: {
    PaymentsCard,
    MonthlyPayments,
  },
  setup() {
    const STORE_MODULE_NAME = 'finance-dashboards'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }

    // card with images
    const ratingsOptions = {
      statTitle: 'Ratings',
      statistics: '13k',
      change: '+38%',
      chipText: 'Year of 2021',
      chipColor: 'primary',
      avatar: require('@/assets/images/avatars/9.png'),
      avatarWidth: '111',
    }
    const sessionsOptions = {
      statTitle: 'Sessions',
      statistics: '24.5k',
      change: '-22%',
      chipText: 'Last Week',
      chipColor: 'secondary',
      avatar: require('@/assets/images/avatars/10.png'),
      avatarWidth: '86',
    }

    const growthAreaChart = {
      statTitle: 'Total Growth',
      statistics: '42.5k',
      series: [
        {
          name: 'Subscribers',
          data: [28, 40, 36, 52, 38, 60, 55],
        },
      ],
    }

    return {
      ratingsOptions,
      sessionsOptions,
      growthAreaChart,
    }
  },
}
</script>
